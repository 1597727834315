import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CompanyItem from './CompanyItem';

const CompanyList = ({ onEdit, onDelete }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/compny`)
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the companies!', error);
      });
  }, []);

  return (
    <div>
      <h2>Company List</h2>
      {companies.map(company => (
        <CompanyItem key={company._id} company={company} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </div>
  );
};

export default CompanyList;
