import React from 'react';

const CompanyItem = ({ company, onEdit, onDelete }) => {
  return (
    <div style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
      <h3>{company.name}</h3>
      <p>Email: {company.email}</p>
      <p>Phone: {company.phone}</p>
      <p>Address: {company.address}</p>
      <p>Active: {company.isActive ? 'Yes' : 'No'}</p>
      <button onClick={() => onEdit(company)}>Edit</button>
      <button onClick={() => onDelete(company._id)}>Delete</button>
    </div>
  );
};

export default CompanyItem;
