import React, {useContext, useEffect, useMemo, useState} from "react";
import {Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import {useMaterialUIController, setMiniSidenav, setOpenConfigurator} from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import {setupAxiosInterceptors} from "./services/interceptor";
import ProtectedRoute from "examples/ProtectedRoute";
import {AuthContext} from "context";
import {Helmet} from "react-helmet";
import CompanyList from "components/company/CompanyList";
import CompanyForm from "components/company/CompanyForm";
import CompanyUpdate from "pages/company/CompanyUpdate";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import Login from "auth/login";
import Register from "auth/register";
import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";

export default function App() {
    const authContext = useContext(AuthContext);

    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const {pathname} = useLocation();

    const [isDemo, setIsDemo] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setIsDemo(process.env.REACT_APP_IS_DEMO === "true");

        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        console.log(accessToken, refreshToken, 'authContext debugging');
        
        if (!!(accessToken && refreshToken)) {
            authContext.login(accessToken, refreshToken);
        } else {
            authContext.logout();
            navigate("/auth/login");
        }
    }, []);

    useEffect(() => {
        console.log(authContext, 'authContext debugging');
        
    }, [authContext]);
    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: "rtl",
            stylisPlugins: [rtlPlugin],
        });

        setRtlCache(cacheRtl);
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route && route.type !== "auth") {
                return (
                    <Route
                        key={route.key}
                        path={route.route}
                        element={
                            <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                                {route.component}
                            </ProtectedRoute>
                        }
                    />
                );
            }
            return null;
        });

    const configsButton = (
        <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{cursor: "pointer"}}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="small" color="inherit">
                settings
            </Icon>
        </MDBox>
    );

    return (
        <>
            {isDemo && (
                <Helmet>

                </Helmet>
            )}
            {direction === "rtl" ? (
                <CacheProvider value={rtlCache}>
                    <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
                        <CssBaseline/>
                        {layout === "dashboard" && (
                            <>
                                <Sidenav
                                    color={sidenavColor}
                                    brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                                    brandName="Suite-Report"
                                    routes={routes}
                                    onMouseEnter={handleOnMouseEnter}
                                    onMouseLeave={handleOnMouseLeave}
                                />
                                <Configurator/>
                                {configsButton}
                            </>
                        )}
                        {layout === "vr" && <Configurator/>}
                        <Routes>
                            <Route path="/auth/login" element={<Login/>}/>
                            <Route path="/auth/register" element={<Register/>}/>
                            <Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
                            <Route path="/auth/reset-password" element={<ResetPassword/>}/>
                            {getRoutes(routes)}
                            <Route path="*" element={<Navigate to="/dashboard"/>}/>
                            {/* Company management routes */}
                            <Route
                                path="company-list"
                                element={
                                    <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                                        <CompanyList/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="company-create"
                                element={
                                    <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                                        <CompanyForm/>
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route
                path="company-edit/:id"
                element={
                  <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                    <CompanyUpdate />
                  </ProtectedRoute>
                }
              /> */}
                        </Routes>
                    </ThemeProvider>
                </CacheProvider>
            ) : (
                <ThemeProvider theme={darkMode ? themeDark : theme}>
                    <CssBaseline/>
                    {layout === "dashboard" && (
                        <>
                            <Sidenav
                                color={sidenavColor}
                                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                                brandName="Suite-Report"
                                routes={routes}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                            <Configurator/>
                            {configsButton}
                        </>
                    )}
                    {layout === "vr" && <Configurator/>}
                    <Routes>
                        <Route path="/auth/login" element={<Login/>}/>
                        {/* <Route path="/auth/register" element={<Register/>}/> */}
                        <Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/auth/reset-password" element={<ResetPassword/>}/>
                        <Route
                            exact
                            path="user-profile"
                            element={
                                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                                    <UserProfile/>
                                </ProtectedRoute>
                            }
                            key="user-profile"
                        />
                        <Route
                            exact
                            path="user-management"
                            element={
                                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                                    <UserManagement/>
                                </ProtectedRoute>
                            }
                            key="user-management"
                        />
                        {getRoutes(routes)}
                        <Route path="*" element={<Navigate to="/dashboard"/>}/>
                    </Routes>
                </ThemeProvider>
            )}
        </>
    );
}
