// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// React and other dependencies
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateUser = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    company_id: "",
    isActive: true,
  });

  const [companies, setCompanies] = useState([]);
  const [statusMessage, setStatusMessage] = useState(""); // State to handle status message
  const [errors, setErrors] = useState({}); // State to handle form validation errors

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/company`);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies!", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setUser({
      ...user,
      isActive: e.target.checked,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!user.username) newErrors.username = "Username is required";
    if (!user.email) newErrors.email = "Email is required";
    if (!user.password) newErrors.password = "Password is required";
    if (!user.company_id) newErrors.company_id = "Company selection is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/inadminuserregister`, user);
      setStatusMessage(response.data.message || "User Successfully Created");
      setTimeout(() => {
        navigate("/user-list");
      }, 1500); // Redirects after 3 seconds
    } catch (error) {
      console.error("Error creating user!", error);
      setStatusMessage(error.response?.data?.message || "An error occurred while creating the user.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Create User
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={handleSubmit}>
                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Username
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="username"
                          value={user.username}
                          onChange={handleInputChange}
                        />
                        {errors.username && (
                          <MDTypography variant="caption" color="error">
                            {errors.username}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Email
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="email"
                          name="email"
                          value={user.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <MDTypography variant="caption" color="error">
                            {errors.email}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Password
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="password"
                          name="password"
                          value={user.password}
                          onChange={handleInputChange}
                        />
                        {errors.password && (
                          <MDTypography variant="caption" color="error">
                            {errors.password}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Company
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <select
                          name="company_id"
                          value={user.company_id}
                          onChange={handleInputChange}
                          style={{ width: "100%", padding: "8px", borderRadius: "4px" }}
                        >
                          <option value="" disabled>
                            Select a company
                          </option>
                          {companies.map((company) => (
                            <option key={company._id} value={company._id}>
                              {company.name}
                            </option>
                          ))}
                        </select>
                        {errors.company_id && (
                          <MDTypography variant="caption" color="error">
                            {errors.company_id}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        First Name
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="firstName"
                          value={user.firstName}
                          onChange={handleInputChange}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Last Name
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="lastName"
                          value={user.lastName}
                          onChange={handleInputChange}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox mb={3}>
                    <label>
                      <input
                        type="checkbox"
                        name="isActive"
                        checked={user.isActive}
                        onChange={handleCheckboxChange}
                      />
                      Active
                    </label>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info" fullWidth>
                      Create User
                    </MDButton>
                  </MDBox>
                </form>
                {statusMessage && (
                  <MDBox mt={2}>
                    <MDTypography variant="body1" color={statusMessage.includes("error") ? "error" : "success"}>
                      {statusMessage}
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CreateUser
