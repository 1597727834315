import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";
import Login from "auth/login";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import Index from "pages/company/Index";
import CompanyCreate from "pages/company/CompanyCreate";
import CompanyUpdate from "pages/company/CompanyUpdate";
import UserList from "pages/users/Index";
import CreateUser from "pages/users/CreateUser";
import UpdateUser from "pages/users/UpdateUser";
import ChangePassword from "pages/users/ChangePassword";
import AdminChangePassword from "layouts/profile/AdminChangePassword";
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  //Uncomment and use these routes if needed
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   // type: "examples",
  //   type: "collapse",
  //   name: "User Profile",
  //   key: "user-profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/user-profile",
  //   component: <UserProfile />,
  // },
  // {
  //   // type: "examples",
  // type: "collapse",
  //   name: "User Management",
  //   key: "user-management",
  //   icon: <Icon fontSize="small">list</Icon>,
  //   route: "/user-management",
  //   component: <UserManagement />,
  // },
  //Uncomment these auth routes if needed
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  //------------------------------------
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  // {
  //   type: "auth",
  //   name: "Register",
  //   key: "register",
  //   icon: <Icon fontSize="small">register</Icon>,
  //   route: "/auth/register",
  //   component: <Register />,
  // },
  // {
  //   type: "auth",
  //   name: "Forgot Password",
  //   key: "forgot-password",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/auth/forgot-password",
  //   component: <ForgotPassword />,
  // },
  // {
  //   type: "auth",
  //   name: "Reset Password",
  //   key: "reset-password",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/auth/reset-password",
  //   component: <ResetPassword />,
  // },
  // Company management routes-------------------------------
  {
    type: "collapse",
    name: "Company List",
    key: "company-list",
    icon: <Icon fontSize="small">business</Icon>,
    route: "/company-list",
    component: <Index />,
  },
  {
    type: "collapse",
    name: "Create Company",
    key: "company-create",
    icon: <Icon fontSize="small">add_business</Icon>,
    route: "/company-create",
    component: <CompanyCreate />,
  },
  {
    name: "Edit Company",
    key: "company-edit",
    route: "/company-edit/:id",
    component: <CompanyUpdate />,
  },
  // User management routes
  {
    type: "collapse",
    name: "User List",
    key: "user-list",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/user-list",
    component: <UserList />,
  },
  {
    type: "collapse",
    name: "Create User",
    key: "user-create",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/user-create",
    component: <CreateUser />,
  },
  {
    name: "Edit User",
    key: "user-edit",
    route: "/user-edit/:id",
    component: <UpdateUser />,
  },
  {
    name: "Change Password",
    key: "user-change-password",
    route: "/user-change-password/:id",
    component: <ChangePassword />,
  },
  {
    name: "Admin Change Password",
    key: "admin-change-password",
    route: "admin-change-password",
    component: <AdminChangePassword />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
];

export default routes;
