import { useState } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function SetNewPassword() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    tokenError: false,
    passwordError: false,
    confirmPasswordError: false,
    error: false,
    errorText: "",
  });

  const [apiResponse, setApiResponse] = useState(null);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (inputs.token.trim().length === 0) {
      setErrors({ ...errors, tokenError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (inputs.confirmPassword.trim() !== inputs.password.trim()) {
      setErrors({ ...errors, confirmPasswordError: true });
      return;
    }

    const formData = {
      token: inputs.token,
      password: inputs.password,
      confirmPassword: inputs.confirmPassword,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/set-new-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      setApiResponse(data);

      if (response.status === 200) {
        setTimeout(() => {
          navigate('/auth/login');
        }, 1500);
      }

    } catch (err) {
      setErrors({ ...errors, error: true, errorText: 'An unexpected error occurred.' });
      console.error('Error:', err);
    }
  };

  return (
    <BasicLayoutLanding image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Set New Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Token"
                fullWidth
                name="token"
                value={inputs.token}
                onChange={changeHandler}
                error={errors.tokenError}
              />
              {errors.tokenError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The token cannot be empty
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
              {errors.passwordError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The password must be at least 6 characters
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                fullWidth
                name="confirmPassword"
                value={inputs.confirmPassword}
                onChange={changeHandler}
                error={errors.confirmPasswordError}
              />
              {errors.confirmPasswordError && (
                <MDTypography variant="caption" color="error" fontWeight="light">
                  The passwords must match
                </MDTypography>
              )}
            </MDBox>
            {errors.error && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {errors.errorText}
              </MDTypography>
            )}
            {apiResponse && (
              <MDTypography variant="caption" color="text" fontWeight="light">
                {JSON.stringify(apiResponse)}
              </MDTypography>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Set New Password
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Remembered your password?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayoutLanding>
  );
}

export default SetNewPassword;
