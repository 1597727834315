import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { TextField, Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import axios from "axios";

function ChangePassword() {
  const [formData, setFormData] = useState({
    email: "",
    oldPassword: "",
    newPassword: ""
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook to handle navigation

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-admin-detail`, {
            token: token // Pass the token in the request body
          });

          const userDetail = response.data.user;
          setFormData(prevState => ({
            ...prevState,
            email: userDetail.email
          }));
        } catch (error) {
          console.error("Error fetching user data", error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/reset-password`, formData);
      setResponseMessage(response.data.message);

      if (response.status === 200) { // Check if HTTP status is 200 OK
        setTimeout(() => {
          navigate("/profile"); // Redirect to the profile page after 3 seconds
        }, 1500); // Redirect after 3 seconds
      }
    } catch (error) {
      setResponseMessage("Error resetting password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ padding: 2 }}>
        <Header>
          <Typography variant="h5" gutterBottom>
            Change Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  readOnly
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Old Password"
                  name="oldPassword"
                  type="password"
                  value={formData.oldPassword}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  name="newPassword"
                  type="password"
                  value={formData.newPassword}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
          {responseMessage && (
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
              {responseMessage}
            </Typography>
          )}
        </Header>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default ChangePassword;
